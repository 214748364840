// Import Vue
import { createApp } from 'vue';

// Import Lottie
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
// import '../css/icons.css';
import '@/sass/style.scss'

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
const app = createApp(App);

// Register Framework7 Vue Components
registerComponents(app);

// Mount the App
app.use(Vue3Lottie).mount('#app');