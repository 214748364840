export const startUserMedia = (video, w, h) => {
  const constraint = {
    audio: false,
    video: {
      facingMode: 'environment',
      width: { min: w, ideal: w },
      height: { min: h, ideal: h },
    }
  };

  const handleSuccess = (stream) => {
    video.value.srcObject = stream;
  }

  const handleError = (error) => {
    console.log(error.message);
  }

  navigator.mediaDevices.getUserMedia(constraint).then(handleSuccess).catch(handleError);
};