<template>
  <f7-app v-bind="f7params">

    <!-- Your main view, should have "view-main" class -->
    <f7-view main class="safe-areas" url="/"></f7-view>

    <!-- Landscape Screen -->
    <div v-if="mob" class="landscape-message">
      <p class="landscape-message-txt text-upper text-spaced">Vire o celular para posição vertical</p>
    </div>

    <!-- Desktop Screen -->
    <div class="desktop-message" v-else>
      
      <svg class="desktop-message-svg" width="1313" height="183">
        <title>#Leia Uma</title>
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#leia-uma"
        />
      </svg>

      <div class="desktop-message-info">
        <p class="desktop-message-intro text-spaced">A diferença entre um autor e uma autora vai além de uma letra. <br><br>
        Queremos mudar essa história. <br><br>
        Por isso, transformamos livros de autores em indicações de autoras mulheres.</p>
        <div class="desktop-message-code">
          <svg class="desktop-message-svg" width="194" height="194">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#qr-prod"
            />
          </svg>
        </div>
        <p class="desktop-message-txt text-upper text-spaced">Para acessar o projeto escaneie o QR Code, depois aponte seu celular para um livro clássico escrito por um homem.</p>
      </div>

      <svg class="desktop-message-svg" width="919" height="190">
        <title>Autora</title>
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#autora"
        />
      </svg>

      <div class="desktop-message-logos display-flex justify-content-center align-items-center">        
        <label>Realização</label>
        <img width="34" height="31" src="../static/logo-bpe-branco.png" alt="Biblioteca Pública do Estado">
        <img width="105" height="43" src="../static/brasao-rs-branco.png" alt="Governo do Estado do Rio Grande do Sul"> 
      </div>

    </div>

    <!-- SVGs Symbols -->
    <Svgs />

  </f7-app>
</template>
<script>
import routes from "../js/routes.js";
import Svgs from './svgs.vue'
import { isMobile } from "../js/helpers/detect-device.js";

export default {
  components: {
    Svgs
  },
  setup() {
    // Framework7 Parameters
    const f7params = {
      name: "LeiaUmaAutora", // App name
      theme: "auto", // Automatic theme detection
      view: {
        browserHistory: true,
        browserHistoryInitialMatch: true,
        browserHistoryRoot: (() => window.location.origin)(),
        browserHistorySeparator: ''
      },
      routes: routes, // App routes
      navbar: {
        mdCenterTitle: true,
      },
    };
    const mob = isMobile();

    return {
      f7params,
      mob,
    };
  },
};
</script>