<template>
  <f7-navbar no-shadow no-hairline transparent>
    <f7-nav-left>
      <f7-link href="/">
        <svg class="c-logo" width="190" height="55">
          <title>#Leia Uma Autora</title>
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#logo"
          />
        </svg>
      </f7-link>    
    </f7-nav-left>
    <f7-nav-right>
      <slot />
    </f7-nav-right>
  </f7-navbar>
</template>