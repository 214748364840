
import HomePage from '../pages/home.vue';
import AboutPage from '../pages/about.vue';
import ResultPage from '../pages/result.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/sobre',
    component: AboutPage,
  },
  {
    path: '/autoras',
    component: ResultPage,
  },
  {
    path: '(.*)',
    component: HomePage,
  },
];

export default routes;