<template>

  <f7-page class="page-app page-app--result" name="result">

    <!-- App Header -->
    <appNav class="navbar--result">
      <f7-link href="/sobre">
        <span class="navbar-text text-upper">sobre</span>
        <svg class="c-info" width="20" height="20">
          <title>Sobre</title>
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#ico-info"
          />
        </svg> 
      </f7-link>
    </appNav>
    <!-- End: App Header -->
    
    <!-- Content -->
    <f7-block>
      <div class="margin-bottom">
        <f7-link class="o-link o-link--back" back>voltar</f7-link>
      </div>
      <p class="text-spaced">Que bom que você chegou até aqui.</p>
      <p class="text-spaced">Abaixo estão apenas algumas das autoras presentes no acervo da Biblioteca Pública do Estado RS.</p>
      <p class="text-spaced">Agora é com você. E quando for escolher o próximo livro para ler, lembre-se: #LeiaUmaAutora.</p>

      <hr>

      <ul class="authors-list">
        <li v-for="(author, index) in authors.sort()" :key="index" class="authors-list-item text-spaced">
          {{ author }}
        </li>
      </ul>

      <hr>

      <p class="text-upper text-spaced text-align-center"><strong>Pesquise outras autoras <br>
      No acervo completo</strong></p>
      <f7-row class="justify-content-center">
        <f7-col width="55">
          <f7-button href="//bpe.suitesaber.org/cgi-bin/wxis.exe/iah/scripts/?IsisScript=iah.xis&lang=pt&base=BPE" target="_blank" link external class="button--secondary">Acesse aqui</f7-button>
        </f7-col>
      </f7-row>
      <br>
      <p class="text-upper text-spaced text-align-center"><strong>Visite a Biblioteca</strong></p>
      <f7-row class="justify-content-center">
        <f7-col width="60">
          <f7-button href="//goo.gl/maps/VUAbttRYpYS2xASj8" target="_blank" link external class="button--secondary">Como chegar</f7-button>
        </f7-col>
      </f7-row>
      <f7-row class="justify-content-center">
        <f7-col class="text-align-center margin-top padding-top">
          <f7-link class="o-link" back>voltar</f7-link>
        </f7-col>
      </f7-row>
    </f7-block>
    <!-- End: Content -->

  </f7-page>

</template>

<script>
import { ref } from 'vue';
import appNav from '../components/appNav.vue';

export default {
  
  components: {
    appNav
  },

  setup() {
    const authors = ref([
      'Jane Austen',
      'Mary Shelley',
      'Agatha Christie',
      'Carolina Maria de Jesus',
      'Margaret Mitchell',
      'Harriet Beecher Stowe',
      'Clarice Lispector ',
      'Charlotte Brontë',
      'Simone de Beauvoir ',
      'Virgínia Woolf ',
      'Jeanne Bourin',
      'Isabel Allende',
      'Frorbela Espanca',
      'Melinda Taub',
      'Santa Teresa de Jesus',
      'Madeline Miller',
      'Willa Cather',
      'Eleanor H. Porter',
      'Colleen McCullough',
      'Margaret Atwood',
      'Hannah Arendt',
      'Ruth Harris',
      'Stephenie Meyer',
      'Octavia E. Butler',
      'Elena Ferrante',
      'Mary Ann Evans',
      'Patricia D. Cornwell',
      'Iris Murdoch',
      'Maria José Dupré',
      'Leticia Wierzchowski',
      'Anne Rice',
      'Harper Lee',
      'Susan Hinton',
      'Martha Medeiros',
      'Hilda Hilst',
      'Alice Walker',
      'J.K. Rowling',
      'Tânia Jamardo Faillace',
      'Pearl S. Buck',
      'Angela Carter',
      'Marguerite Yourcenar',
      'Françoise Sagan',
      'Lya Luft',
      'Lygia Fagundes Telles',
      'Anais Nin',
      'Gabrielle S. Colette',
      'Anne Frank ',
      'Marion Zimmer Bradley',
      'Doris Lessing',
      'Marguerite Duras',
      'Clarissa Pinkola Estés',
      'Suzanne Collins',
      'Emily Brontë',
    ]);

    return {
      authors
    }
  }
}
</script>