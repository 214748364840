export const drawBoundingBoxes = (canvas, boxes) => {
  if (!boxes) return
  // draw bounding boxes
  for (let i = 1; i < boxes.length; i++) {
    drawBoundingBox(canvas, boxes[i].boundingPoly.vertices);
  }
};

const drawBoundingBox = (canvas, vertices) => {
  if (!vertices) return
  // get canvas context
  const ctx = canvas.getContext('2d');
  // drawing each vertice
  ctx.beginPath();
  ctx.lineWidth = '2';
  ctx.strokeStyle = '#CE9629';
  ctx.moveTo(vertices[0].x, vertices[0].y);
  for (let i = 1; i < vertices.length; i++) {
    ctx.lineTo(vertices[i].x, vertices[i].y);
  }
  ctx.closePath();
  ctx.stroke();
};