<template>

  <f7-page class="page-app" name="about">

    <!-- App Header -->
    <appNav>
      <f7-link back>
        <svg class="c-close" width="16" height="17">
          <title>Fechar</title>
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#ico-close"
          />
        </svg> 
      </f7-link>
    </appNav>
    <!-- End: App Header -->
    
    <!-- Content -->
    <f7-block>
      <h2 class="about-title text-wide text-upper text-spaced">Sobre o projeto</h2>
      <p class="about-text"><strong>Leia Uma Autora</strong> é um projeto da Biblioteca Pública do Estado do Rio Grande do Sul para dar visibilidade às autoras e suas obras. Elas sempre estiveram lá, mas nem sempre foram lidas e reconhecidas.</p>
      <p class="about-text">A partir de uma tecnologia de reconhecimento de caracteres, as capas dos livros escritos por homens se transformam em indicações de obras escritas por mulheres.</p>
      <p class="about-text">Hackeamos a visibilidade dos autores e colocamos as mulheres na capa.</p>
      <p class="about-text">#LeiaUmaAutora</p>
      <div class="about-logos display-flex justify-content-center align-items-center">
        <label>Realização</label>
        <img width="34" height="31" src="../static/logo-bpe.png" alt="Biblioteca Pública do Estado">
        <img width="105" height="43" src="../static/brasao-rs-alt.png" alt="Governo do Estado do Rio Grande do Sul"> 
      </div>
    </f7-block>
    <!-- End: Content -->

  </f7-page>

</template>

<script>
import appNav from '../components/appNav.vue';

export default {
  components: {
    appNav
  }
}
</script>