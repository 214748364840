<template>

  <f7-page 
    :class="[{ 'page-app--start' : userPermission }, 'page-app']"
    name="home" 
    @page:beforeout="onPageBeforeOut"
    @page:reinit="onPageReinit"
  >

    <!-- App Header -->
    <appNav :class="{ 'navbar--home' : userPermission }">
      <f7-link href="/sobre">
        <span class="navbar-text text-upper">sobre</span>
        <svg class="c-info" width="20" height="20">
          <title>Sobre</title>
          <use
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xlink:href="#ico-info"
          />
        </svg> 
      </f7-link>
    </appNav>
    <!-- End: App Header -->
    
    <!-- Camera App --> 
    <div :class="[{ 'is-scanning' : scanning }, 'camera-wrapper']">

      <div class="camera camera--media">
        <video ref="videoRef" playsinline autoplay></video>
      </div>

      <div class="camera camera--canvas">
        <canvas ref="canvasRef"></canvas>
      </div>

      <div class="scan-area"></div>

      <div class="button-wrapper">
        <f7-row class="justify-content-center">
          <f7-col width="50">
            <f7-button class="button button--primary" @click="scan">Escanear</f7-button>
          </f7-col>
        </f7-row>
      </div>
    </div>
    <!-- End: Camera App --> 

    <!-- Camera Permission -->
    <div class="intro" @click="enableCamera">
      <p class="text-upper text-wide text-spaced">Precisamos acessar <br>a câmera do seu celular</p>
      <Vue3Lottie :animationData="camShutterJSON" :height="'auto'" :width="'12.18%'" />
      <p class="intro-instruction text-upper text-spaced">Toque na tela para habilitar</p>
      <p class="intro-instruction intro-instruction--small text-upper text-spaced">depois aponte seu celular <br>para um livro clássico <br>escrito por um homem</p>
    </div>
    <!-- End: Camera Permission -->

    <!-- Sheet Modal -->
    <f7-sheet
      class="sheet-swipe-modal no-hairline"
      style="height:auto;"
      backdrop
      swipe-to-close
      close-by-backdrop-click
    >
      <f7-toolbar>
        <f7-link class="o-link" sheet-close>escanear novamente</f7-link>
      </f7-toolbar>
      <f7-page-content>
        <f7-block>
          <div class="close-mark" sheet-close></div>
          <div v-html="modaltext"></div>
        </f7-block>
      </f7-page-content>
    </f7-sheet>
    <!-- End: Sheet Modal -->

  </f7-page>

</template>

<script>
import { f7 } from 'framework7-vue';
import { onMounted, ref } from 'vue';
import axios from 'axios';
import { isMobile } from '../js/helpers/detect-device';
import { startUserMedia } from '../js/helpers/start-user-media';
import { drawBoundingBoxes } from '../js/helpers/draw-bounding-boxes'
import appNav from '../components/appNav.vue';
// import { books } from '../js/data';

export default {
  components: {
    appNav
  },
  methods: {
    onPageReinit() {
      this.init();
    },
    onPageBeforeOut() {
      // Close opened sheets on page out
      f7.sheet.close();
      if (this.videoRef.srcObject) {
        this.stopStreamedVideo(this.videoRef);
      }
    },
    stopStreamedVideo(videoElem) {
      const stream = videoElem.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach(function(track) {
        track.stop();
      });

      videoElem.srcObject = null;
    }
  },
  setup() { 
    // variable and DOM
    const camShutterJSON = ref(require('../static/cam-shutter.json'));
    const userPermission = ref(false);
    const scanning = ref(false);
    const modaltext = ref('');
    const videoRef = ref(null);
    const canvasRef = ref(null);
    const w = 720; // width for image, video, canvas elements
    const h = 720; // height for image, video, canvas elements
    const API_KEY = process.env.API_KEY; // Input your API Key for Cloud Vision Version API
    const ENDPOINT = `https://vision.googleapis.com/v1/images:annotate?key=${API_KEY}`;
    const REQUEST_OPTION = {
      requests: [
        {
          image: {
            content: null,
          },
          features: [
            {
              type: 'TEXT_DETECTION'
            },
          ],
        },
      ],
    };
    let books = [];
    let femaleBook = '';
    let femaleAuthor = '';
    let maleAuthor = '';
    let storyType = '';

    onMounted(async () => {
      // check API key
      if (!API_KEY) {
        console.log('Please input the Cloud Vision API key');
        return;
      }
      // set canvas width/heigh
      canvasRef.value.width = w;
      canvasRef.value.height = h;

      // get Google Spreadsheet Data
      axios.get(`https://sheets.googleapis.com/v4/spreadsheets/17Z4tb9j524Kl6xeLLseEH41KVOwV9rVhBGTbnA5LO_s/values/Dados?alt=json&key=${API_KEY}`)
      .then((res) => {
        const [headers, ...rows] = res.data.values;
        const dataObj = rows.map((r) =>
          // converts data into an array of objects
          headers.reduce((o, h, j) => Object.assign(o, { [h]: r[j] }), {})
        );
        // store data object in books
        return books = dataObj;
      })
      .then(init()) // init stream
      .catch((err) => console.log(err));
    });

    const enableCamera = () => {
      userPermission.value = !userPermission.value;
      init();
    };

    const init = () => {
      if (isMobile()) {
        userPermission.value && startUserMedia(videoRef, w, h);
      } else {
        console.log('not mobile!');
      }
    };

    const reset = () => {
      femaleBook = '';
      femaleAuthor = '';
      maleAuthor = '';
      storyType = '';
      canvasRef.value.getContext('2d').clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
    };

    const handleModal = () => {
      f7.sheet
        .open('.sheet-swipe-modal', true)
        .on('sheetClose', () => reset());
    };

    // no text detected
    const noData = (textScan = '') => {
      modaltext.value = `<p>Desculpe, nós não conseguimos identificar a imagem que você escaneou.</p>
      <p>Mesmo assim, você ainda pode aproveitar para conhecer grandes autoras disponíveis no acervo da Biblioteca Pública do Estado do RS.</p>
      <div class="hashtag text-extawide text-spaced text-upper">
        <span>Leia Uma</span>
        <span>Autora</span>
      </div>
      <div class="text-align-center">
        <div class="row justify-content-center">
          <div class="col-55">
            <a class="link button button--secondary" href="/autoras">acesse aqui</a>
          </div>
        </div>
      </div>`;

      dataLayer.push({
        'event': 'bookScan',
        'type': 'imagem sem texto',
        'book': '',
        'author': '',
        'text': ''
      });

      handleModal();
    };

    // image scan
    const scan = () => {
      // start scan animation
      scanning.value = true;
      ocr(videoRef.value);
    };

    const ocr = (image) => {
      // draw image to canvas
      canvasRef.value.getContext('2d').drawImage(image, 0, 0, canvasRef.value.width, canvasRef.value.height);
      // convert to base64
      const base64Data = canvasRef.value.toDataURL().replace('data:image/png;base64,', '');
      // recognize
      recongize(base64Data);

      // console.log(window.dataLayer);
    };

    // Filter strings for comparison
    const filterStr = (string) => string.normalize('NFD').replace(/\n|\-/g, ' ').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s]||^\s+|\s+$)/g, '').toUpperCase();

    // Recognize scan
    const recongize = (base64Data) => {
      REQUEST_OPTION.requests[0].image.content = base64Data;
      axios.post(ENDPOINT, REQUEST_OPTION)
      .then(response => {
        const annotations = response.data.responses[0].textAnnotations;
        const description = filterStr(annotations[0].description);
        const relatedMaleBook = books.find(book => book['TÍTULO'] && (description.includes(filterStr(book['TÍTULO']))));
        const relatedFemaleBook = books.find(book => book['TÍTULO'] && (description.includes(filterStr(book['TÍTULO CORRESPONDENTE'])) && description.includes(filterStr(book['AUTORA']))));
        const relatedFemaleAuthor = books.find(book => (!book['TÍTULO'] && book['AUTOR'] && book['AUTORA']) && description.includes(filterStr(book['AUTOR'])));
        const relatedMaleAuthor = books.find(book => book['AUTOR'] && description.includes(filterStr(book['AUTOR'])));
        const notRelatedFemaleAuthor = books.find(book => book['AUTORA'] && description.includes(filterStr(book['AUTORA'])));

        // console.log(books); 
        // console.log(annotations); 
        // console.log(description);
        // console.log(relatedMaleBook);
        // console.log(relatedFemaleBook);
        // console.log(relatedFemaleAuthor);
        // console.log(relatedMaleAuthor);
        // console.log(notRelatedFemaleAuthor);

        if (!annotations) {
          noData();
        } else {
          // draw bounding boxes and show result
          drawBoundingBoxes(canvasRef.value, annotations);

          // male author book 
          if (relatedMaleBook) {
            storyType = relatedMaleBook['RELAÇÃO DA HISTÓRIA'].toLowerCase();
            maleAuthor = relatedMaleBook['AUTOR'];
            femaleAuthor = relatedMaleBook['AUTORA'];
            femaleBook = relatedMaleBook['TÍTULO CORRESPONDENTE'];
            modaltext.value = `<p>Há outros autores que merecem ser tão lidos e conhecidos quanto <span class="text-underline">${maleAuthor}</span>. E não são, simplesmente, porque estes autores são autoras.</p>
            <p>Experimente <strong class="text-underline">${femaleBook}</strong>, que também ${storyType} e foi escrito por <strong class="text-underline">${femaleAuthor}</strong>, uma mulher.</p>
            <div class="hashtag text-extawide text-spaced text-upper">
              <span>Leia Uma</span>
              <span>Autora</span>
            </div>
            <div class="text-align-center">
              <p class="text-upper"><strong>Conheça esta e outras autoras que estão disponíveis no acervo da biblioteca pública do estado do Rio Grande do Sul.</strong></p>
              <div class="row justify-content-center">
                <div class="col-55">
                  <a class="link button button--secondary" href="/autoras">acesse aqui</a>
                </div>
              </div>
            </div>`;

            dataLayer.push({
              'event': 'bookScan',
              'type': 'livro autor',
              'book': relatedMaleBook['TÍTULO'],
              'author': maleAuthor,
              'text': description
            });
          }

          // female author book 
          else if (relatedFemaleBook) {
            femaleAuthor = relatedFemaleBook['AUTORA'];
            femaleBook = relatedFemaleBook['TÍTULO CORRESPONDENTE'];
            modaltext.value = `<p>Ficamos felizes em saber que você está lendo <strong class="text-underline">${femaleBook}</strong> da <strong class="text-underline">${femaleAuthor}</strong>. O que acha de aumentar seu repertório?</p>
            <div class="hashtag text-extawide text-spaced text-upper">
              <span>Leia Uma</span>
              <span>Autora</span>
            </div>
            <div class="text-align-center">
              <p class="text-upper"><strong>Conheça ainda mais obras escritas por mulheres no acervo da Biblioteca Pública do Estado do RS.</strong></p>
              <div class="row justify-content-center">
                <div class="col-55">
                  <a class="link button button--secondary" href="/autoras">acesse aqui</a>
                </div>
              </div>
            </div>`;

            dataLayer.push({
              'event': 'bookScan',
              'type': 'livro autora',
              'book': femaleBook,
              'author': femaleAuthor,
              'text': description
            });
          }

          // male author with related female author
          else if (relatedFemaleAuthor) {
            maleAuthor = relatedFemaleAuthor['AUTOR'];
            femaleAuthor = relatedFemaleAuthor['AUTORA'];
            modaltext.value = `<p>Há outros autores que merecem ser tão lidos e conhecidos quanto <span class="text-underline">${maleAuthor}</span>. E não são, simplesmente, porque estes autores são autoras.</p>
            <p>Experimente <strong class="text-underline">${femaleAuthor}</strong>, uma mulher que, assim como ele, possui grandes obras dignas de reconhecimento.</p>
            <div class="hashtag text-extawide text-spaced text-upper">
              <span>Leia Uma</span>
              <span>Autora</span>
            </div>
            <div class="text-align-center">
              <p class="text-upper"><strong>Conheça esta e outras autoras que estão disponíveis no acervo da biblioteca pública do estado do Rio Grande do Sul.</strong></p>
              <div class="row justify-content-center">
                <div class="col-55">
                  <a class="link button button--secondary" href="/autoras">acesse aqui</a>
                </div>
              </div>
            </div>`;

            dataLayer.push({
              'event': 'bookScan',
              'type': 'autora relacionada',
              'book': '',
              'author': maleAuthor,
              'text': description
            });
          }

          // male author
          else if (relatedMaleAuthor) {
            maleAuthor = relatedMaleAuthor['AUTOR'];
            modaltext.value = `<p>Há outros autores que merecem ser tão lidos e conhecidos quanto <span class="text-underline">${maleAuthor}</span>. E não são, simplesmente, porque estes autores são autoras.</p>
            <p>Experimente ler uma obra escrita por uma mulher. O acervo da Biblioteca Pública do Estado do Rio Grande do Sul está repleto delas.</p>
            <div class="hashtag text-extawide text-spaced text-upper">
              <span>Leia Uma</span>
              <span>Autora</span>
            </div>
            <div class="text-align-center">
              <div class="row justify-content-center">
                <p class="text-upper"><strong>Conheça esta e outras autoras que estão disponíveis no acervo da biblioteca pública do estado do Rio Grande do Sul.</strong></p>
                <div class="col-55">
                  <a class="link button button--secondary" href="/autoras">acesse aqui</a>
                </div>
              </div>
            </div>`;

            dataLayer.push({
              'event': 'bookScan',
              'type': 'somente autor',
              'book': '',
              'author': maleAuthor,
              'text': description
            });
          }

          // female author
          else if (notRelatedFemaleAuthor) {
            femaleAuthor = notRelatedFemaleAuthor['AUTORA'];
            modaltext.value = `<p>Ficamos felizes em saber que você está lendo uma obra de <strong class="text-underline">${femaleAuthor}</strong>, uma mulher. O que acha de aumentar seu repertório?</p>
            <div class="hashtag text-extawide text-spaced text-upper">
              <span>Leia Uma</span>
              <span>Autora</span>
            </div>
            <div class="text-align-center">
              <p class="text-upper"><strong>Conheça ainda mais obras escritas por mulheres no acervo da Biblioteca Pública do Estado do RS.</strong></p>
              <div class="row justify-content-center">
                <div class="col-55">
                  <a class="link button button--secondary" href="/autoras">acesse aqui</a>
                </div>
              </div>
            </div>`;

            dataLayer.push({
              'event': 'bookScan',
              'type': 'somente autora',
              'book': '',
              'author': femaleAuthor,
              'text': description
            });
          }

          // no result found
          else {
            modaltext.value = `<p>Desculpe, nós não conseguimos localizar o título que você escaneou.</p>
            <p>Mesmo assim, você ainda pode aproveitar para conhecer grandes autoras disponíveis no acervo da Biblioteca Pública do Estado do RS.</p>
            <div class="hashtag text-extawide text-spaced text-upper">
              <span>Leia Uma</span>
              <span>Autora</span>
            </div>
            <div class="text-align-center">
              <div class="row justify-content-center">
                <div class="col-55">
                  <a class="link button button--secondary" href="/autoras">acesse aqui</a>
                </div>
              </div>
            </div>`;

            dataLayer.push({
              'event': 'bookScan',
              'type': 'texto não identificado',
              'book': '',
              'author': '',
              'text': description
            });
          }

          handleModal();
        }
      }).catch(error => {
        noData();

        console.log(error);
      })
      .finally(() => {
        scanning.value = false
      });
    };

    return {
      camShutterJSON,
      userPermission,
      enableCamera,
      scanning,
      modaltext,
      videoRef,
      canvasRef,
      scan,
      init
    }
  }
}
</script>